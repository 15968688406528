import ReactMDEditor, { MDEditorProps } from "@uiw/react-md-editor";
import { Guid } from "guid-typescript";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KuikaAppManager } from "../../../shared/utilty/kuika-app-manager";
import { CommonProps } from "../common-props";
import "./style.css";

const backendUrl = KuikaAppManager.GetBackendUrl();

type MDViewerProps = MDEditorProps;

interface MDViewerState {
  uniqueKey: Guid;
}

interface ITempBase64 {}

declare let window: Window & { kuika: any };

class MDViewer extends PureComponent<MDViewerProps & CommonProps, MDViewerState> {
  setTimeID = undefined as any;

  private memoizedDynamicCssResult = "";

  constructor(props: MDViewerProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  handleAnchors = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const anchors = node.getElementsByTagName("a") as any;
    for (const element of anchors) {
      element.target = "_blank";
    }
  };

  handleSizeChange = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const sourceNode: HTMLElement = node.getElementsByClassName("w-md-editor-preview")[0] as HTMLElement;
    const firstNode: HTMLElement = node.getElementsByClassName("w-md-editor-content")[0] as HTMLElement;
    firstNode.style.height = `${sourceNode.clientHeight.toString()}px`;
    const secondNode: HTMLElement = node.getElementsByClassName("w-md-editor-show-preview")[0] as HTMLElement;
    secondNode.style.height = `${sourceNode.clientHeight.toString()}px`;
    this.setTimeID = setTimeout(this.handleSizeChange, 200);
  };

  getValue = () => {
    if (this.props.value) {
      const value = this.props.value.replaceAll("{KUIKARESOURCES/}", `${backendUrl}/resource/runtime/byid?resourceId=`);
      return value;
    }
  };

  componentWillUnmount() {
    clearTimeout(this.setTimeID);
  }

  componentDidUpdate() {
    this.handleAnchors();
  }

  componentDidMount() {
    this.setDynamicStyle();
    this.handleSizeChange();
    this.handleAnchors();
  }

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { textDecoration, paddingLeft, paddingTop, paddingRight, paddingBottom, width, height } = this.props.style;
    if (textDecoration) {
      result += `.${className.trim()} .wmde-markdown-color p {
          text-decoration: ${textDecoration} !important;
        }`;
    }
    if (paddingLeft || paddingTop || paddingRight || paddingBottom) {
      result += `.${className.trim()} {
        padding: ${`${paddingTop}px` ?? 0} ${`${paddingRight}px` ?? 0} ${`${paddingBottom}px` ?? 0} ${
        `${paddingLeft}px` ?? 0
      };
      }`;

      let paddingLeftX: any = paddingLeft ?? 0;

      let newWidth = this.getNumbersOfStyleProperties(width) - paddingLeftX;

      result += `.${className.trim()} .w-md-editor {
        padding: 0 !important;
        width: ${newWidth}px !important;
      }`;
    }

    result += `.${className.trim()} {
      background: ${this.props.style?.backgroundColor ?? "white"};
    }`;

    result += `.${className.trim()} .w-md-editor-show-preview .w-md-editor-preview {
      height: max-content;
    }`;

    result += `.${className.trim()} .w-md-editor-text-input::selection {
      color: white;
      -webkit-text-fill-color: white;
    }`;

    result += `.${className.trim()} .w-md-editor-text-input::-moz-selection {
      color: white;
      -webkit-text-fill-color: white;
    }`;

    result += `.${className.trim()} .w-md-editor-text-input::-webkit-selection {
      color: white;
      -webkit-text-fill-color: white;
    }`;

    if (!height) {
      result += `.${className.trim()} {
        min-height: 32px;
      }`;
    }

    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} kmdviewer_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getStyle = () => {
    const styleObj: React.CSSProperties = {};
    if (this.props.style?.height) {
      styleObj.height = this.props.style?.height;
    }
    if (this.props.style?.minHeight) {
      styleObj.minHeight = this.props.style?.minHeight;
    }
    if (this.props.style?.maxHeight) {
      styleObj.maxHeight = this.props.style?.maxHeight;
    }
    if (this.props.style?.width) {
      styleObj.width = this.props.style?.width;
    }
    if (this.props.style?.minWidth) {
      styleObj.minWidth = this.props.style?.minWidth;
    }
    if (this.props.style?.maxWidth) {
      styleObj.maxWidth = this.props.style?.maxWidth;
    }
    return styleObj;
  };

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return numb;
  };

  render(): ReactNode {
    return (
      <>
        <div className={`kuika_mdViewer${this.getClassName()}`} style={this.getStyle()}>
          <ReactMDEditor {...this.props} value={this.getValue()} hideToolbar={true} preview="preview"></ReactMDEditor>
        </div>
      </>
    );
  }
}

const mdViewer = withCommonEvents(MDViewer);
export { mdViewer as MDViewer };
