import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent } from "react";
import { KComponentFunctions } from "../../../../../shared/utilty/component-functions";
import "./table.scss";

declare let window: any;

export interface TableRowProps {
  rowData?: any;
  rowIndex?: number;
  style?: any;
  hoverFontColor?: string;
  hoverBgColor?: string;
  tableClassName?: Guid;
  dynamicBackGroundColor?: string;
  calculatedRowBgColor?: (rowIndex?: number, rowData?: any) => string;
  calculatedRowFontColor?: (rowIndex?: number, rowData?: any) => string;
  onClick?: (e: any, rowIndex: number, rowData: any) => void;
  rowComponent?: any;
  showCursorPointer?: boolean;
  editMode?: boolean;
  children?: any;
  showTableHeader?: boolean;
  transformedOnMobileResolution?: boolean;
}

export interface TableRowState {
  mouseOver: boolean;
  uniqueKey?: Guid;
  editModeEnabled?: boolean;
  expanded?: boolean;
  isMobileResolution?: boolean;
}

export class TableRow<P, S> extends PureComponent<TableRowProps & P, TableRowState> {
  private memoizedDynamicCssResult = "";

  constructor(props: TableRowProps) {
    super(props as TableRowProps & P);
    this.state = {
      mouseOver: false,
      uniqueKey: Guid.create(),
      editModeEnabled: false,
      expanded: false,
      isMobileResolution: true
    } as TableRowState & S;

    this.handleResize = _.debounce(this.handleResize, 1000).bind(this);

    window.addEventListener("resize", this.handleResize);
  }

  private memoizedScreenWidth = 0;

  componentDidMount() {
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime) return;
    const screenWidth = window.innerWidth;
    if (screenWidth === this.memoizedScreenWidth || !this.props.transformedOnMobileResolution) return;
    this.memoizedScreenWidth = screenWidth;
    const table = this.getParentTable();
    const isMobileResolution = screenWidth < 576;
    const childrenLength = React.Children.toArray(this.props.children)?.length;
    if (screenWidth < 576 && childrenLength > 3) {
      if (table && !table.classList.contains("kuika_table__wrapper__mobile")) {
        table.classList.add("kuika_table__wrapper__mobile");
        if (this.props.showTableHeader === false) {
          table.classList.add("kuika_table__wrapper__mobile--witout-header");
        }
      }
    } else if (screenWidth >= 576) {
      if (table && table.classList.contains("kuika_table__wrapper__mobile")) {
        table.classList.remove("kuika_table__wrapper__mobile");
        table.classList.remove("kuika_table__wrapper__mobile--witout-header");
      }
    }
    if (this.state.isMobileResolution === isMobileResolution) return;
    this.setState({ isMobileResolution });
  };

  getClassName = () => {
    let result = "";
    const isReportDesigner = window.kuika?.dashboardState === 17;
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `ktablerow_${this.state.uniqueKey.toString().substring(0, 8)}`;
    if (isReportDesigner) {
      result += " kuika_report-table-row";
    }
    return result;
  };

  getStyle = (): any => {
    const isReportDesigner = window.kuika?.dashboardState === 17;
    let result: any = {};
    if (this.props.style) {
      result = _.clone(this.props.style);
    }

    if (this.props.dynamicBackGroundColor && window.kuika?.isDesignTime !== true) {
      const dataFieldKey = new RegExp(/\[datafield:([a-zA-Z0-9-_]+)\]/);
      const matchResult = this.props.dynamicBackGroundColor.match(dataFieldKey);
      if (matchResult) {
        const dataField = matchResult[1];
        const bgValue = this.props.rowData && this.props.rowData[dataField];
        if (bgValue) {
          result.backgroundColor = bgValue;
        }
      }
    }

    if (this.state.mouseOver === true && !isReportDesigner) {
      if (this.props.hoverFontColor) {
        result.color = this.props.hoverFontColor;
      }
      if (this.props.hoverBgColor) {
        result.backgroundColor = this.props.hoverBgColor;
      }
      if (this.props.showCursorPointer) {
        result.cursor = "pointer";
      }
    }

    if (result?.display) {
      delete result.display;
    }

    return result;
  };

  handleMouseOver = () => {
    const className = this.getClassName();
    const isReportDesigner = window.kuika?.dashboardState === 17;

    if (!className || isReportDesigner) {
      return;
    }
    const currentRow = document.querySelector(`.${className}`);
    const targets: any = currentRow?.querySelectorAll(`td`);

    targets.forEach((element: any) => {
      element.style.backgroundColor = this.props.hoverBgColor || "";
      element.style.color = this.props.hoverFontColor || "";
      element.style.cursor = this.props.showCursorPointer ? "pointer" : "";
    });

    this.setState({
      mouseOver: true
    } as TableRowState & S);
  };

  handleMouseOut = () => {
    const className = this.getClassName();
    if (!className) {
      return;
    }
    const currentRow = document.querySelector(`.${className}`);
    const targets: any = currentRow.querySelectorAll(`td`);

    targets.forEach((element: any) => {
      element.style.backgroundColor = "";
      element.style.color = "";
      element.style.cursor = "";
    });
    this.setState({
      mouseOver: false
    } as TableRowState & S);
  };

  handleRowClick = (e: any) => {
    if (
      this.props.rowComponent &&
      this.props.rowComponent.props.onClick &&
      this.props.rowIndex !== undefined &&
      this.props.rowData
    ) {
      if (KComponentFunctions.isButtonElement(e.target) === true) {
        return;
      }
      if (KComponentFunctions.isImgElement(e.target) === true) {
        return;
      }
      this.props.rowComponent.props.onClick(e, this.props.rowIndex, this.props.rowData);
    }
  };

  toggleExpand = () => {
    this.setState(
      (prevState: TableRowState & S) =>
        ({
          expanded: !prevState.expanded
        } as Pick<TableRowState & S, "expanded">)
    );
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    delete props.children;
    return props;
  };

  getParentTable = () => {
    const parentTable = document.querySelector(`.${this.props.tableClassName}`);
    return parentTable;
  };

  handleExpandButtonClick = () => {
    this.setState({ expanded: !this.state.expanded } as Pick<TableRowState & S, "expanded">);
  };

  renderExpandButton() {
    const { expanded } = this.state;
    return (
      <div className="kuika_table__wrapper__expand-btn" onClick={this.handleExpandButtonClick}>
        {expanded ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.08516 8.12789L5.4289 10.7842C5.1411 11.072 4.67394 11.072 4.38614 10.7842C4.09834 10.4963 4.09834 10.0292 4.38614 9.74139L7.59367 6.53386C7.86548 6.26205 8.30553 6.26205 8.57664 6.53386L11.7842 9.74139C12.072 10.0292 12.072 10.4963 11.7842 10.7842C11.4964 11.072 11.0292 11.072 10.7414 10.7842L8.08516 8.12789Z"
              fill="#292C33"
            />
          </svg>
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.08513 9.20211L10.7414 6.54585C11.0292 6.25805 11.4963 6.25805 11.7841 6.54585C12.072 6.83365 12.072 7.30081 11.7841 7.58861L8.57662 10.7961C8.30481 11.068 7.86476 11.068 7.59364 10.7961L4.38612 7.58861C4.09831 7.30081 4.09831 6.83365 4.38612 6.54585C4.67392 6.25805 5.14107 6.25805 5.42887 6.54585L8.08513 9.20211Z"
              fill="#292C33"
            />
          </svg>
        )}
      </div>
    );
  }

  renderMobileRow() {
    const { expanded } = this.state;
    const { children } = this.props;
    const childrenArray = React.Children.toArray(children);

    const isAnyAlwaysVisibleColumn = childrenArray.some(
      (child: any) => child?.props?.additionalProps?.alwaysVisibleOnMobileResolution === false
    );

    const isEveryColumnAlwaysVisible = childrenArray.every(
      (child: any) => child?.props?.additionalProps?.alwaysVisibleOnMobileResolution === false
    );

    const filteredChildren = isAnyAlwaysVisibleColumn
      ? childrenArray.filter((child: any) => child?.props?.additionalProps?.alwaysVisibleOnMobileResolution !== false)
      : childrenArray;

    return (
      <tr
        {...this.getProps()}
        onClick={this.handleRowClick}
        style={this.getStyle()}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        className={this.getClassName()}
      >
        {expanded || isEveryColumnAlwaysVisible ? children : filteredChildren}
        {isAnyAlwaysVisibleColumn && !isEveryColumnAlwaysVisible && this.renderExpandButton()}
      </tr>
    );
  }

  renderDesktopRow() {
    return (
      <tr
        {...this.props}
        onClick={this.handleRowClick}
        style={this.getStyle()}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        className={this.getClassName()}
      >
        {this.props.children}
      </tr>
    );
  }

  render() {
    const { isMobileResolution } = this.state;
    const isDesignTime = window.kuika?.isDesignTime;
    const childrenArray = React.Children.toArray(this.props.children);
    if (isMobileResolution && !isDesignTime && childrenArray?.length > 3) {
      return this.renderMobileRow();
    }
    return this.renderDesktopRow();
  }
}
