import MDEdit from "@uiw/react-md-editor";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import { ImageLibraryService } from "../../../services/image-library-service";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface MDEditorProps {
  value: string;
  onChange?: (value: string) => void;
  style?: CSSProperties;
}

interface MDEditorState {
  content: string;
  tempBase64: ITempBase64[];
  uniqueKey: Guid;
}

interface ITempBase64 {
  id: string;
  content: string;
}

declare let window: Window & { kuika: any };

class MDEditor extends PureComponent<MDEditorProps & CommonProps, MDEditorState> {
  refKey: Guid = Guid.create();

  isDesignTime: boolean = window.kuika?.isDesignTime;

  private memoizedDynamicCssResult = "";

  constructor(props: MDEditorProps) {
    super(props);
    this.state = {
      content: "",
      tempBase64: [],
      uniqueKey: Guid.create()
    };
  }

  clickAddImageButton = (e: any) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".png, .jpg, .svg";

    input.onchange = (e: any) => {
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = (readerEvent: any) => {
        const result = reader.result?.toString();
        if (result) {
          this.updateImageBase64(this.state.content, result);
        }
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  clickHandleEditOrPreview = (val: string) => {
    if (val === "edit") {
      this.handleEdit();
    } else if (val === "preview") {
      this.handlePreview();
    }
  };

  handleEdit = () => {};

  handlePreview = () => {};

  componentDidMount = () => {
    this.setDynamicStyle();
    const addImageButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[12]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    const editButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[18]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    const previewButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[20]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;

    if (addImageButton) {
      addImageButton.addEventListener("click", this.clickAddImageButton);
    }
    if (editButton) {
      editButton.addEventListener("click", () => this.clickHandleEditOrPreview("edit"));
    }
    if (previewButton) {
      previewButton.addEventListener("click", () => this.clickHandleEditOrPreview("preview"));
    }
  };

  componentWillUnmount = () => {
    const addImageButton = window.document.evaluate(
      `//*[@id="${this.refKey}"]/div/div[1]/ul/li[12]/button`,
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    ).singleNodeValue;
    if (addImageButton) {
      addImageButton.removeEventListener("click", this.clickAddImageButton);
    }
  };

  emitOnChange = (value: string): void => {
    if (!this.props.onChange) return;
    this.props.onChange(value);
  };

  handleChangeEditor = (value?: string) => {
    this.setState({ content: !_.isEmpty(value) ? value : "" }, () => this.emitOnChange(value));
  };

  updateImageBase64 = (value: string, image: string) => {
    if (value) {
      const searchString = "https://example.com/your-image.png";
      if (this.state.content.indexOf(searchString) > -1 && image.length > 0) {
        const tempObject = {} as ITempBase64;
        tempObject.id = Guid.create().toString();
        tempObject.content = image;
        ImageLibraryService.SaveResource(tempObject);
        value = value.replace(searchString, `{KUIKARESOURCES/}${tempObject.id}`);
        this.setState({ content: value, tempBase64: [...this.state.tempBase64, tempObject] });
        if (this.props.onChange) {
          this.props.onChange(value);
        }
      }
    }
  };

  getStyle = (): CSSProperties => {
    const style: CSSProperties = { ...this.props.style };

    if (style.display == "inline") {
      style.display = "inline-block";
    }

    return style;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { color, fontSize, borderColor } = this.props.style;
    if (color) {
      result += `.${className.trim()} .language-markdown {
          color: ${color} !important;
        }`;
    }
    if (fontSize) {
      result += `.${className.trim()} .w-md-editor-text {
          font-size: ${fontSize} !important;
        }`;
    }
    result += `.${className.trim()} .w-md-editor {
        border: none !important;
      }`;
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result}kmdeditor_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  getValue = () => {
    return this.state?.content && this.state.content !== "" ? this.state.content : this.props.value;
  };

  render(): ReactNode {
    return (
      <div id={this.refKey.toString()} className={this.getClassName()}>
        <MDEdit
          {...this.props}
          height={this.props.style?.height as number}
          style={this.getStyle()}
          onChange={this.handleChangeEditor}
          value={this.getValue()}
        />
      </div>
    );
  }
}

const mdEditor = withCommonEvents(MDEditor);
export { mdEditor as MDEditor };
