import { Rate } from "antd";
import { RateProps } from "antd/lib/rate";
import { PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

type RatingProps = RateProps;

interface RatingState {}

class Rating extends PureComponent<RatingProps & CommonProps, RatingState> {
  constructor(props: RatingProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const node = findDOMNode(this) as Element;
    if (node) {
      node.setAttribute("focusid", this.props.id);
    }
  }

  render(): ReactNode {
    return (
      <>
        <Rate {...this.props} />
      </>
    );
  }
}

const rating = withCommonEvents(Rating);
export { rating as Rating };
