import { InboxOutlined } from "@ant-design/icons";
import { Progress } from "antd";
import Dragger from "antd/es/upload/Dragger";
import axios from "axios";
import _ from "lodash";
import { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KuikaAppManager } from "../../../shared/utilty/kuika-app-manager";

declare let window: Window & { kuika: any };

const PreviewPath = "/home/UploadedFiles";
const isKuikaDomain = window.location.href.includes("kuika.com");
class BigFileUpload extends Component<any, any> {
  chunkSize;

  constructor(props) {
    super(props);
    this.chunkSize = 1048576 * props.chunkNumber;
    this.state = {
      showProgress: false,
      counter: 0,
      fileToBeUpload: {},
      beginningOfTheChunk: 0,
      endOfTheChunk: this.chunkSize,
      progress: 0,
      fileGuid: "",
      fileSize: 0,
      chunkCount: 0
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fileToBeUpload !== prevState.fileToBeUpload && this.state.fileSize > 0) {
      this.fileUpload(this.state.counter);
    }

    if (this.state.progress !== prevState.progress && this.state.progress != 100) {
      this.fileUpload(this.state.counter);
    }
  }

  getFileContext = (e) => {
    const isDesignTime = window.kuika?.isDesignTime;
    if (isDesignTime) {
      return;
    }

    this.resetChunkProperties();
    const _file = e.fileList[0];
    this.setState({ fileSize: _file.size });

    const _totalCount =
      _file.size % this.chunkSize === 0 ? _file.size / this.chunkSize : Math.floor(_file.size / this.chunkSize) + 1;
    this.setState({ chunkCount: _totalCount, fileToBeUpload: _file });

    const _fileID = `${uuidv4()}.${_file.name.split(".").pop()}`;
    this.setState({ fileGuid: _fileID });
  };

  fileUpload = (count) => {
    if (this.state.counter <= this.state.chunkCount) {
      const chunk = this.state.fileToBeUpload.originFileObj.slice(
        this.state.beginningOfTheChunk,
        this.state.endOfTheChunk
      );
      this.uploadChunk(chunk);
      this.setState((prevState) => ({
        counter: prevState.counter + 1,
        beginningOfTheChunk: prevState.endOfTheChunk,
        endOfTheChunk: prevState.endOfTheChunk + this.chunkSize
      }));
    }
  };

  uploadChunk = async (chunk) => {
    const isDesignTime = window.kuika?.isDesignTime;
    try {
      const url = new URL(`${KuikaAppManager.GetBackendUrl()}/resource/UploadChunks`);
      const response = await axios.post(url.toString(), chunk, {
        params: {
          id: this.state.counter,
          fileName: this.state.fileGuid,
          savePath: isKuikaDomain ? PreviewPath : this.props.savePath,
          chunkCount: this.props.chunkNumber
        },
        headers: { "Content-Type": "application/json" }
      });

      const data = response.data;
      if (data.isSuccess) {
        if (this.state.counter >= this.state.chunkCount) {
          console.log("Process is complete, counter", this.state.counter);
          if (this.props.onUploadFinish) {
            this.props.onUploadFinish();
          }
          await this.uploadCompleted();
        } else {
          const percentage = (this.state.counter / this.state.chunkCount) * 100;
          this.setState({ progress: percentage });
        }
      } else {
        console.log("Error Occurred:", data.errorMessage);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  uploadCompleted = async () => {
    const isDesignTime = window.kuika?.isDesignTime;
    const url = new URL(`${KuikaAppManager.GetBackendUrl()}/resource/UploadComplete`);
    const formData = new FormData();
    formData.append("fileName", this.state.fileGuid);

    const response = await axios.post(
      url.toString(),
      {},
      {
        params: {
          fileName: this.state.fileGuid,
          savePath: isKuikaDomain ? PreviewPath : this.props.savePath
        },
        data: formData
      }
    );

    const data = response.data;
    if (data.isSuccess) {
      this.setState({ progress: 100, counter: 0 });
    }
  };

  resetChunkProperties = () => {
    this.setState({
      showProgress: true,
      progress: 0,
      counter: 0,
      beginningOfTheChunk: 0,
      endOfTheChunk: this.chunkSize
    });
  };

  getStyle = () => {
    const style = _.cloneDeep(this.props.style);
    const isDesignTime = window.kuika?.isDesignTime;
    style.pointerEvents = isDesignTime ? "none" : "auto";
    return style;
  };

  render() {
    const { showProgress, fileToBeUpload, progress } = this.state;
    const progressInstance = <Progress percent={progress} />;
    console.log(progress);
    return (
      <>
        <Dragger
          disabled={progress > 0 && progress !== 100}
          multiple
          fileList={[]}
          beforeUpload={() => false}
          action={undefined}
          style={this.getStyle()}
          onChange={this.getFileContext}
        >
          <p style={this.getStyle()} className="ant-upload-drag-icon">
            <InboxOutlined color={this.getStyle().color ?? undefined} />
          </p>
          <p style={this.getStyle()} className="ant-upload-text">
            {this.props.label}
          </p>
          <p style={this.getStyle()} className="ant-upload-hint">
            {this.props.hint}
          </p>
        </Dragger>
        <div style={{ display: showProgress ? "block" : "none" }}>{fileToBeUpload.name}</div>
        <div style={{ display: showProgress ? "block" : "none" }}>{progressInstance}</div>
      </>
    );
  }
}

const bigfileupload = withCommonEvents(BigFileUpload);
export { bigfileupload as BigFileUpload };
