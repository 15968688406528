import { Input } from "antd";
import _ from "lodash";
import React, { PureComponent } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface NumericboxProps extends NumberFormatProps {
  onChange: any;
}

interface NumericboxState {}

class Currency extends PureComponent<NumericboxProps & CommonProps, NumericboxState> {
  constructor(props: NumericboxProps) {
    super(props);
    this.state = {};
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (!style) {
      style = {
        width: "100%"
      };
      props.style = style;
    } else if (!style.width) {
      style.width = "100%";
      props.style = style;
    }

    if (props.onChange) {
      delete props.onChange;
    }
    if (props.decimalSeparator) {
      delete props.decimalSeparator;
    }
    if (props.value && props.value.value) {
      const tmp = _.clone(props.value.floatValue);
      delete props.value;
      props.value = tmp;
    }
    return props;
  };

  onChange = (value: number | any) => {
    if (this.props.onChange) {
      const newValue = value.value != undefined ? _.clone(value.floatValue) : value;
      this.props.onChange(newValue);
    }
  };

  getThousandSeparator = () => {
    if (this.props.decimalSeparator == ",") {
      return ".";
    }
    return ",";
  };

  getDecimalSeparator = () => {
    return this.props.decimalSeparator ? this.props.decimalSeparator : ".";
  };

  render(): React.ReactNode {
    if (this.props.value && typeof this.props.value === "string" && window.kuika?.dashboardState === 1) {
      return <Input value={this.props.value} style={this.props.style} />;
    }
    return (
      <NumberFormat
        {...this.getProps()}
        customInput={Input}
        onValueChange={this.onChange}
        decimalSeparator={this.getDecimalSeparator()}
        thousandSeparator={this.getThousandSeparator()}
      />
    );
  }
}

const currency = withCommonEvents(Currency);
export { currency as Currency };
